import { Drag } from "./drag";
import { WoordZoeker } from "./game";
import { Letter } from "./letter";

export class Grid {

    letters: Letter[];
    drag: Drag | null = null;
    game: WoordZoeker;

    constructor(letters: string[][], size: number, scene: WoordZoeker){
        this.letters = [];
        const width = 300;
        const letterSize = width / size;
        this.game = scene;

        for(let x = 0; x < size; x++){
            for(let y = 0; y < size; y++){
                this.letters.push(new Letter(letters[x][y].toUpperCase(), x, y, letterSize, scene));
            }
        }
    }

    startDrag(pointer: {x: number, y: number}){
        this.letters.forEach(letter => {
            if(letter.pointerOver(pointer.x, pointer.y)){
                this.drag = new Drag(letter, this, this.game);
            }
        })
    }

    moveDrag(pointer: {x: number, y: number}){
        for(let i = 0; i < this.letters.length; i ++){
            const letter  =this.letters[i];
            if(letter.pointerOver(pointer.x, pointer.y)){
                if(this.drag === null){
                    return;
                }

                if(letter === this.drag.lastLetter){
                    return;
                }
         
                    this.drag.dragToLetter(letter);
                
            }
        }
    }

    destroy(){
        this.letters.forEach(letter => {
            letter.destroy();
        });
    }

    getLetter(x: number, y: number){
        return this.letters.find(letter => (letter.x === x && letter.y === y));
    }

    endDrag(){
        const word = (this.drag?.word)?.toLowerCase();
        if(word){
           if(this.game.didFindWord(word)){

               this.drag?.letters.forEach(l => l.done());
           } else {
            this.game.playSound("deselect");
           }
        }

        this.letters.forEach(letter => {
            letter.deselect();
        });

        this.drag = null;
    }
}