import { WoordZoeker } from "./game";

export class Letter {
    character: string;
    x: number;
    y: number;

    size: number;

    selected: boolean = false;
    isDone: boolean = false;

    shape: Phaser.GameObjects.Rectangle;
    text: Phaser.GameObjects.DOMElement;

    circle: Phaser.GameObjects.Shape;

    scene: WoordZoeker;

    constructor(char: string, x: number, y: number, size: number, scene: WoordZoeker){
        this.character = char[0];
        this.x = x;
        this.y = y;
        this.size = size;

        this.scene = scene;

        this.shape = scene.add.rectangle(x * size + 10, y * size + 90 + WoordZoeker.config.gridOffsetY, size, size).setOrigin(0, 0).setAlpha(0);

        const fontSize = size / 2;

        const style = {
            'font': fontSize + "px " + WoordZoeker.config.letterFont,
            'width': size +"px",
            'height': size +"px",
            'line-height': size +"px",
            'text-align': 'center',
            'pointer-events': 'none',
            'color': WoordZoeker.config.letterColor,
            'cursor': 'default'
        }
        this.circle = scene.add.circle(x * size + 10 + size/2, y * size + 90 + size/2 + WoordZoeker.config.gridOffsetY, size / 3, Phaser.Display.Color.HexStringToColor(WoordZoeker.config.circleColor).color ).setAlpha(0).setDepth(10);
        this.text = scene.add.dom(x * size + 10, y * size + 90 + WoordZoeker.config.gridOffsetY, 'div', style, this.character).setOrigin(0, 0);
    }

    pointerOver(x: number, y: number){
        return (x > (this.shape.x) && x < (this.shape.x + this.size) && y > (this.shape.y) && y < (this.shape.y + this.size ))
    }

    destroy(){
        this.shape.destroy();
        this.circle.destroy();
        this.text.destroy();
    }

    select(){
        if(this.selected === true){
            return;
        }
        this.selected = true;
        this.circle.fillColor = Phaser.Display.Color.HexStringToColor(WoordZoeker.config.circleColor).color;


        this.circle.setAlpha(WoordZoeker.config.circleAlpha);
    }

    toString(){
        return this.character + " - " + this.x + ", " + this.y;
    }

    deselect(){
        if(this.selected === false){
            return;
        }
        this.selected = false;
        if(this.isDone){
            this.circle.fillColor = Phaser.Display.Color.HexStringToColor(WoordZoeker.config.circleFoundColor).color;
            this.circle.setAlpha(WoordZoeker.config.circleAlpha);
        } else {
            this.circle.fillColor = Phaser.Display.Color.HexStringToColor(WoordZoeker.config.circleColor).color;
            this.circle.setAlpha(0);
        }
        // this.doneCircle?.setAlpha(WoordZoeker.config.circleAlpha);
        // this.circle?.setAlpha(0);
    }

    done(){
        const style = {
            'font': this.size + "px " + WoordZoeker.config.letterFont,
            'width': this.size +"px",
            'height': this.size +"px",
            'line-height': this.size +"px",
            'text-align': 'center',
            'pointer-events': 'none',
            'color': WoordZoeker.config.letterFoundColor
        }
        this.isDone = true;

        this.circle.fillColor = Phaser.Display.Color.HexStringToColor(WoordZoeker.config.circleFoundColor).color;
        (this.text.node as HTMLDivElement).style.color = WoordZoeker.config.letterFoundColor;
        // this.text.destroy();
        // this.text = this.scene.add.dom(this.x * this.size + 10, this.y * this.size + 50, 'div', style, this.character).setOrigin(0, 0);
        this.circle.setAlpha(1);
    }





}