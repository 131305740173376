import { WoordZoeker } from "./game";
import { Grid } from "./grid";
import { Letter } from "./letter";

type DragDirection = 'hor' | 'ver' | 'dia';

export class Drag {

    word: string = "";
    direction: DragDirection | null = null;
    backwards: boolean = false;

    firstLetter: Letter;
    lastLetter: Letter | null = null;

    letters: Letter[] = [];

    grid: Grid;
    scene: WoordZoeker;

    line?: Phaser.GameObjects.Line;

    constructor(letter: Letter, grid: Grid, scene: WoordZoeker){
        this.firstLetter = letter;
        letter.select();
        this.grid = grid;
        this.scene = scene;
    }

    getDirection(letter: Letter){
        const deltaX = Math.abs(letter.x - this.firstLetter.x);
        const deltaY = Math.abs(letter.y - this.firstLetter.y);

        this.backwards = false;

        if(deltaX === deltaY){
            this.direction = "dia";
            if(this.firstLetter.x > letter.x)
            this.backwards = true;
        } else if (deltaX > deltaY){
            this.direction = "hor";
            if(this.firstLetter.x > letter.x)
            this.backwards = true;
        } else if (deltaY > deltaX){
            this.direction = "ver";
            if(this.firstLetter.y > letter.y)
            this.backwards = true;
        }

        return this.direction;
    }

    reset(){
        this.word = "";
        this.letters.forEach(letter => letter.deselect());
        this.letters = [];
    }

    dragToLetter(letter: Letter){
        this.reset();

        if(this.firstLetter === letter){
            this.firstLetter.select();
            return;
        }

        if(this.letters[this.letters.length - 1] === letter){
            return;
        }

        const deltaX = Math.abs(letter.x - this.firstLetter.x);
        const deltaY = Math.abs(letter.y - this.firstLetter.y);



        if (deltaY === 0){
            this.direction = "hor";
        } else if (deltaX === 0){
            this.direction = "ver";
        } else {
            const ratio = deltaX / deltaY;
            if(ratio < 0.5){
                this.direction = "ver"
            } else if (ratio > 2){
                this.direction = "hor";
            } else {
                this.direction = "dia";
            }
        }

        switch(this.direction){
            case "ver":{
                this.verticalSelection(letter);
                break;
            }
            case "hor":{
                this.horizontalSelection(letter);
                break;
            }
            case "dia": {
                this.diagonalSelection(letter);
            }
        }
    }

    diagonalSelection(letter: Letter){
        var dy = this.firstLetter.y <= letter.y ? 1 : -1;
        var dx = this.firstLetter.x <= letter.x ? 1 : -1

        var y = this.firstLetter.y;
        var x = this.firstLetter.x;

        let letter2;

        for(let i = 0; i < (Math.abs(this.firstLetter.x - letter.x) + 1); i++){

            letter2 = this.grid.getLetter(x, y);

            if(letter2){
                this.addLetter(letter2);
            }
            x += dx;
            y += dy;
            if(x === letter.x + dx || y === letter.y + dy){
                break;
            }
        }

        if(this.lastLetter === letter2){
            return;
        }

        if(letter2){
            this.lastLetter = letter2;

            this.drawLine(this.firstLetter, letter2);
            this.scene.playSound("letterSelected");
        }

    }

    horizontalSelection(letter: Letter){
        var delta = this.firstLetter.x <= letter.x ? 1 : -1;

        let letter2;

        for(var x = this.firstLetter.x; x != letter.x + delta; x += delta)
        {
            letter2 = this.grid.getLetter(x, this.firstLetter.y);

            if(letter2){

                this.addLetter(letter2);

            }
        }

        if(this.lastLetter === letter2){
            return;
        }


        if(letter2){
            this.lastLetter = letter2;
            this.scene.playSound("letterSelected");
            this.drawLine(this.firstLetter, letter2);
        }

    }

    verticalSelection(letter: Letter){
        var delta = this.firstLetter.y <= letter.y ? 1 : -1;

        let letter2;

        for(var y = this.firstLetter.y; y != letter.y + delta; y += delta)
        {
            letter2 = this.grid.getLetter(this.firstLetter.x, y);
            if(letter2){


                this.addLetter(letter2);
            }
        }

        if(this.lastLetter === letter2){
            return;
        }

        if(letter2){
            this.lastLetter = letter2;
            this.scene.playSound("letterSelected");
            this.drawLine(this.firstLetter, letter2);
        }
    }

    addLetter(letter: Letter){
        this.letters.push(letter);
        letter.select();
        this.word += letter.character;
    }

    drawLine(startLetter: Letter, endLetter: Letter){
        return;
        this.line?.destroy();
        const x1 = startLetter.circle.x;
        const y1 = startLetter.circle.y;
        const x2 = endLetter.circle.x;
        const y2 = endLetter.circle.y;
        this.line = this.scene.add.line(0, 0, x1, y1, x2, y2, 0xff0000, 0.5).setLineWidth(10).setOrigin(0, 0);
    }
}