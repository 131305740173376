import Phaser, { Game } from "phaser";
import { GameConfig } from "./config";
import { Grid } from "./grid";
import { Platform } from "./Platform";
import { WordFind } from "./wordfind";

export class GameStarter {
	imageLoadCounter = 4;
	config: GameConfig;
	width: number;
	height: number;

	constructor(config: GameConfig) {
		this.width = window.innerWidth;
		this.height = window.innerHeight;
		this.config = config;
		document.addEventListener("DOMContentLoaded", () => {
			this.init();
		});
	}

	load(key: string, src: string) {
		let img = new Image();
		img.id = key;
		img.hidden = true;
		img.onload = () => {
			document.body.append(img);
			this.imageReady();
		};
		img.onerror = () => {
			console.error("Img " + key + " with source " + src + " not found");
			document.body.append(img);
			this.imageReady();
		};
		// img.src = this.path + src;
	}

	init() {
		const container = document.getElementById("container");
		const phaserConfig: Phaser.Types.Core.GameConfig = {
			type: Phaser.CANVAS,
			parent: container ? container : undefined,
			width: 320,
			height: 570,
			scene: [new WoordZoeker(this.config, this.width, this.height)],
			render: {
				transparent: true,
			},
			scale: {
				mode: Phaser.Scale.WIDTH_CONTROLS_HEIGHT,
			},
			dom: {
				createContainer: true,
				pointerEvents: "auto",
			},
			audio: {
				disableWebAudio: true,
			},
			// fps: {
			// 	limit: 10,
			// 	smoothStep: true
			// }
		};
		const game = new Game(phaserConfig);
	}

	imageReady() {
		this.imageLoadCounter--;
		if (this.imageLoadCounter === 0) {
			this.init();
		}
	}
}

export let SPEED: number = 1;

export class WoordZoeker extends Phaser.Scene {
	static config: GameConfig;

	playing: boolean = false;

	grid!: Grid;

	platform: Platform;

	score: number = 0;

	isGameOver: boolean = false;

	_time: number = 0;

	wordsList!: Phaser.GameObjects.DOMElement;
	ul!: HTMLUListElement;
	ulContainer!: HTMLDivElement;

	words: { word: string; found: boolean }[] = [];

	constructor(config: GameConfig, width: number, height: number) {
		super({});
		WoordZoeker.config = config;
		this.platform = new Platform();
	}

	preload() {
		this._time = WoordZoeker.config.timeLimit;
	}

	progress: number = 100;

	create() {
		this.platform.preloadSounds(WoordZoeker.config);

		this.game.domContainer.style.width = "100%";
		this.game.domContainer.style.height = "100%";
		this.ulContainer = document.createElement("div");
		this.ulContainer.style.display = "flex";
		this.ulContainer.style.backgroundColor = this.hexToRgb(WoordZoeker.config.wordsContainerColor, +WoordZoeker.config.wordsContainerAlpha)
		this.ulContainer.style.position = "absolute";
		this.ulContainer.style.zIndex = "-1";
		this.ulContainer.style.borderRadius =
			WoordZoeker.config.wordsContainerBorderRadius + "px";
		this.ulContainer.style.border =
			"solid " + WoordZoeker.config.wordsContainerBorderWidth + "px";
		this.ulContainer.style.borderColor =
			WoordZoeker.config.wordsContainerBorderColor;
		this.ulContainer.style.boxSizing = "border-box";

		console.log(WoordZoeker.config.wordsContainerAlpha)
		this.ulContainer.style.opacity = "50%";


		this.ul = document.createElement("ul");

		this.game.domContainer.style.touchAction = "none";

		const rectangle = this.add.graphics();
		rectangle.fillStyle(
			Phaser.Display.Color.HexStringToColor(WoordZoeker.config.gridColor)
				.color,
			WoordZoeker.config.gridAlpha
		);

		rectangle.fillRoundedRect(
			10,
			WoordZoeker.config.gridOffsetY + 90,
			300,
			300,
			WoordZoeker.config.gridBorderRadius
		);

		rectangle.lineStyle(
			WoordZoeker.config.gridBorderWidth,
			Phaser.Display.Color.HexStringToColor(
				WoordZoeker.config.gridBorderColor
			).color,
			WoordZoeker.config.gridAlpha
		);

		rectangle.stroke();

		this.input.on(
			"pointerdown",
			(pointer: any) => {
				if (this.isGameOver) {
					return;
				}
				this.grid.startDrag(pointer);
			},
			this
		);

		this.input.on("pointermove", (pointer: any) => {
			if (this.isGameOver) {
				return;
			}

			this.grid.moveDrag(pointer);
		});

		this.input.on("pointerout", (pointer: any) => {
			if (this.grid.drag === null) {
				return;
			}
			this.grid.endDrag();
			this.playSound("deselect");
		});

		this.input.on("pointerup", () => {
			if (this.isGameOver) {
				return;
			}
			this.grid.endDrag();
		});

		this.game.domContainer.addEventListener(
			"pointerdown",
			(e: PointerEvent) => {
				e.preventDefault();

				let rect = this.game.canvas.getBoundingClientRect();
				var x =
					(e.clientX - rect.left) /
					(+this.game.canvas.style.width.split("p")[0] / 320); //x position within the element.
				var y =
					(e.clientY - rect.top) /
					(+this.game.canvas.style.height.split("p")[0] / 570); //y position within the element.
				var pos = { x: x, y: y };

				this.input.emit("pointerdown", pos);
			}
		);

		document.body.addEventListener("pointermove", (e: PointerEvent) => {
			e.preventDefault();

			let rect = this.game.canvas.getBoundingClientRect();
			var x =
				(e.clientX - rect.left) /
				(+this.game.canvas.style.width.split("p")[0] / 320); //x position within the element.
			var y =
				(e.clientY - rect.top) /
				(+this.game.canvas.style.height.split("p")[0] / 570); //y position within the element.
			var pos = { x: x, y: y };

			this.input.emit("pointermove", pos);
		});

		this.game.domContainer.addEventListener(
			"pointerup",
			(e: PointerEvent) => {
				e.preventDefault();
				this.input.emit("pointerup");
			}
		);

		this.game.domContainer.addEventListener(
			"pointerleave",
			(e: PointerEvent) => {
				e.preventDefault();
				this.input.emit("pointerout");
			}
		);

		this.wordsList = this.add
			.dom(0, 400 + WoordZoeker.config.wordsContainerOffsetY, "div")
			.setOrigin(0, 0);

		this._time = WoordZoeker.config.timeLimit;

		const ws = this.generateGrid();

		const words = WordFind().solve(ws, WoordZoeker.config.words).found;

		this.words = words.map((word: any) => {
			return { word: word.word, found: false };
		});

		this.score = 0;

		this.drawGrid(ws);

		this.drawWords();

		// this._scoreText.setText("0");
		this.platform.sendScore(0);

		this.platform.init(this, WoordZoeker.config);
	}

	update(time: number, delta: number): void {
		if (this.playing === false) {
			return;
		}

		if (this._time > 0) {
			this._time -= delta;
			console.log(this._time)
		}

		let size = this._time / WoordZoeker.config.timeLimit;
		size = size * 100;

		if (size < 0) {
			this.playSound("timeUp");
			this.gameOver();
			size = 0;
		}

		this.platform.sendTimeleft(size);
	}

	drawWords() {
		this.ulContainer.innerHTML = "";
		this.ulContainer.style.marginLeft = "10px";
		this.ulContainer.style.width = "calc(100% - 20px)";

		const background = document.createElement("div");

		this.ul.innerHTML = "";

		const ul = this.ul;

		ul.style.display = "flex";
		ul.style.justifyContent = "flex-start";
		ul.style.flexWrap = "wrap";

		ul.style.listStyleType = "none";
		ul.style.width = "100%";
		ul.style.padding = "5px 0px 5px 0px";

		ul.style.font =
			WoordZoeker.config.wordSize + "px " + WoordZoeker.config.wordFont;
		(ul.style.textAlign = "center"),
			(ul.style.color = WoordZoeker.config.wordColor);
		ul.style.lineHeight = WoordZoeker.config.wordSize + "px";

		this.words.forEach((word) => {
			const li = document.createElement("li");

			li.style.cursor = "default";
			li.style.flex = `0 0 ${
				100 / WoordZoeker.config.wordsContainerRows
			}%`;

			li.innerHTML = word.word;

			if (word.found) {
				li.style.textDecorationColor = WoordZoeker.config.lineColor;
				li.style.textDecorationLine = "line-through";
				li.style.textDecorationThickness =
					WoordZoeker.config.lineThickness + "px";
			}
			ul.appendChild(li);
		});

		this.ulContainer.appendChild(ul);
		this.wordsList.setElement(this.ulContainer);
	}

	drawGrid(grid: string[][]) {
		const size = WoordZoeker.config.size;

		this.grid = new Grid(grid, size, this);
	}

	play() {
		this.isGameOver = false;
		this.playing = true;
		this.platform.gamestarted();
	}

	timebonusInterval: number = 0;

	startTimeBonus() {
		this.playing = false;

		this.platform.freezeTimeLeftEvent = false;

		const timeLeft = this._time;
		const timeD = timeLeft / 39;

		let wait = 1000;

		if (WoordZoeker.config.timeLimit > 0) {
			wait = 2000;
			for (let i = 1; i < 41; i++) {
				setTimeout(() => {
					console.log(i);
					this.score += timeD * WoordZoeker.config.pointsPerMs;
					setTimeout(() => {
						this.platform.sendScore(this.score);
					}, 10);

					const size =
						((timeLeft - i * timeD) /
							WoordZoeker.config.timeLimit) *
						100;

					this.platform.sendTimeleft(size, true);
				}, i * 50);
			}
		}

		setTimeout(() => {
			this.platform.sendTimeleft(0, true);
			setTimeout(() => {
				this.gameOver();
			}, 50);
		}, wait);
	}

	restart() {
		if (this.grid) {
			this.grid.destroy();
		}

		this.progress = 100;

		// (<HTMLElement>document.getElementById("myBar")).style.width = "100%";
		this.platform.sendTimeleft(100);

		// this.game.domContainer.replaceChildren('');

		this.isGameOver = false;
		const ws = this.generateGrid();

		const words = WordFind().solve(ws, WoordZoeker.config.words).found;

		this.words = words.map((word: any) => {
			return { word: word.word, found: false };
		});

		this.score = 0;

		this._time = WoordZoeker.config.timeLimit;

		this.drawGrid(ws);

		this.drawWords();

		this.platform.sendScore(0);
		this.platform.sendTimeleft(100);

		this.platform.ready();
	}

	gameOver() {
		if (this.isGameOver) {
			return;
		}
		this.playing = false;
		this.isGameOver = true;

		this.platform.gameover(Math.round(this.score));
	}

	clickCanvas() {
		for (let i = 0; i < 10; i++) {
			var evt = new PointerEvent("pointer", {
				view: window,
				clientX: 20,
				clientY: 20,
			});
			document.dispatchEvent(evt);
		}
	}

	didFindWord(word: string) {
		const wordWord = this.words.find((w) => w.word.toLowerCase() === word);
		if (wordWord && !wordWord.found) {
			this.score += WoordZoeker.config.pointsPerWord;
			this.playSound("wordFound");
			this.platform.sendScore(Math.round(this.score), true);

			wordWord.found = true;
			this.drawWords();

			if (
				this.words.filter((word) => word.found === false).length === 0
			) {
				this.playSound("finished");
				this.startTimeBonus();
			}
			return true;
		}
		return false;
	}

	generateGrid() {
		let orientations = [];
		// var allOrientations = ['horizontal','horizontalBack','vertical','verticalUp',
		// 'diagonal','diagonalUp','diagonalBack','diagonalUpBack'];
		if (WoordZoeker.config.backwards === true) {
			orientations.push(
				"horizontal",
				"vertical",
				"horizontalBack",
				"verticalUp"
			);
			if (WoordZoeker.config.diagonal) {
				orientations.push(
					"diagonal",
					"diagonalUp",
					"diagonalBack",
					"diagonalUpBack"
				);
			}
		} else {
			orientations.push("horizontal", "vertical");
			if (WoordZoeker.config.diagonal) {
				orientations.push("diagonal", "diagonalBack");
			}
		}

		let words: string[] = [];

		for (let i = 0; i < WoordZoeker.config.words.length; i++) {
			let word = WoordZoeker.config.words[i];
			if (word.length <= WoordZoeker.config.size) {
				words.push(word);
			}
		}

		const options: any = {
			height: WoordZoeker.config.size,
			width: WoordZoeker.config.size,
			orientations: orientations,
			fillBlanks: true,
			allowExtraBlanks: true,
			maxAttempts: 20,
			maxGridGrowth: 0,
			preferOverlap: true,
		};

		return WordFind().newPuzzle(words, options);
	}

	sounds = {} as any;

	playSound(soundKey: string, loop: boolean = false) {
		const config = WoordZoeker.config as any;
		if (config[soundKey + "Sound"])
			this.platform.playSound(config.path + config[soundKey + "Sound"]);
		//   const sounds = this.sounds as any;
		//   if(sounds[soundKey] !== undefined){
		//     (sounds[soundKey] as Howl).loop(loop);
		//     sounds[soundKey].stop();
		//     sounds[soundKey].play();
		//   }
	}

	hexToRgb(hex: string, alpha: number) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		
		if(!result){
			return hex;
		}
		const r = parseInt(result[1], 16)
		const g = parseInt(result[2], 16)
		const b = parseInt(result[3], 16)

		return `rgba(${r},${g},${b},${alpha})`
	  }
}
