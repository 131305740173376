import { GameConfig, defaultConfig, printConfig } from "./config";
import { GameStarter } from "./game";

const customConfig: any = getSettingsFromURI(location.search);

let config: any = defaultConfig;

if(customConfig)
for(const [key, value] of Object.entries(customConfig)){
      if(key === "words"){
        config[key] = (<string> value).split(",");
      } else {
        config[key] = value;
      }

}

printConfig(defaultConfig)

const game = new GameStarter(<GameConfig>config);

function getSettingsFromURI(uri: string){
    let settingString;
    const split = uri.split("&")
    for(let s of split){
      if(s[0] === "s"){
        settingString = atob(s.split("=")[1]);
      }
    }

    if(settingString === undefined){
      return null;
    }

    let jsonString = "{";
    let settings: string[] = settingString.split("\n");

    settings.forEach(s => {
      const keyAndValue = s.split("=");
      if(keyAndValue.length === 1){
        return;
      }


      const key = keyAndValue[0].trim();
      const value = keyAndValue[1].trim();


      if(value === "default" || key === "cache"){
          return;
      }

      let line = '"' + key + '": ';
      if(isString(value) || key === "wordsContainerAlpha"){
        line += '"' + value + '"';
    } else {
        line += value;
    }
      if(settings.indexOf(s) < (settings.length - 2)){
        line += ",\n";
      }

      jsonString += line;
    });

    jsonString += "}";
    return JSON.parse(jsonString);
}





function isString(value: string){
    if(!isNaN(Number(value))|| value === "true" || value === "false"){
        return false;
    }
    return true;
}



