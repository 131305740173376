export interface GameConfig {
	path: string;
	size: number;
	words: string[];
	diagonal: boolean;
	backwards: boolean;
	timeLimit: number;
	pointsPerMs: number;
	pointsPerWord: number;

	wordFont: string;
	wordColor: string;
	wordFoundColor: string;
	wordSize: number;

	letterFont: string;
	letterColor: string;
	letterFoundColor: string;

	scoreFont: string;
	scoreColor: string;
	scoreSize: number;
	scoreOffsetY: number;

	timeBarColor: string;
	timeBarBackgroundColor: string;
	timeBarBorderRadius: number;
	timeBarBorderColor: string;
	timeBarBorderWidth: number;
	timeBarOffsetY: number;

	gridOffsetY: number;

	gridColor: string;
	gridAlpha: number;
	gridBorderRadius: number;
	gridBorderWidth: number;
	gridBorderColor: string;

	circleColor: string;
	circleFoundColor: string;
	circleAlpha: number;

	lineThickness: number;
	lineColor: string;

	wordsContainerColor: string;
	wordsContainerAlpha: string;
	wordsContainerOffsetY: number;
	wordsContainerBorderRadius: number;
	wordsContainerRows: number;
	wordsContainerBorderColor: string;
	wordsContainerBorderWidth: number;

	wordFoundSound: string;
	timeUpSound: string;
	finishedSound: string;
	letterSelectedSound: string;
	deselectSound: string;
	timeBonusSound: string;
}

export const defaultConfig: GameConfig = {
	path: "https://gamecdn.playenwin.com/wietse/woordzoeker/default/",
	size: 4,
	words: ["geit"],
	backwards: false,
	diagonal: false,
	timeLimit: 10000,
	pointsPerMs: 0.01,
	pointsPerWord: 10000,

	wordColor: "#000000",
	wordFoundColor: "#000000",
	wordSize: 15,
	wordsContainerRows: 3,

	circleColor: "#FFFFFF",
	circleFoundColor: "#E97340",
	circleAlpha: 1,

	letterColor: "#000000",
	letterFoundColor: "#000000",
	scoreColor: "#000000",
	scoreOffsetY: 0,
	scoreSize: 30,

	gridColor: "#40E9CA",
	gridAlpha: 1,
	gridBorderRadius: 0,
	gridBorderColor: "black",
	gridBorderWidth: 0,

	wordsContainerOffsetY: 0,
	wordsContainerColor: "#000000",
	wordsContainerAlpha: "0.4",
	wordsContainerBorderRadius: 10,
	wordsContainerBorderColor: "black",
	wordsContainerBorderWidth: 0,

	lineThickness: 3,
	lineColor: "#000000",

	letterFont: "Acme",
	wordFont: "Source Sans Pro",
	scoreFont: "Arial",

	gridOffsetY: 0,
	timeBarBackgroundColor: "black",
	timeBarBorderColor: "black",
	timeBarBorderRadius: 10,
	timeBarBorderWidth: 1,
	timeBarOffsetY: 0,
	timeBarColor: "#00FF07",

	wordFoundSound: "",
	timeUpSound: "",
	finishedSound: "",
	letterSelectedSound: "",
	deselectSound: "",
	timeBonusSound: "",
};

export function printConfig(config: any) {
	let string = "";
	for (const [key, value] of Object.entries(config)) {
		string += key + "=" + value + "\n";
	}
}
